import { SUB_VERSION_MIGRATION, SUB_EX_OTHER } from '@/store/const/expertise'

// Установили текущую версию данных
SUB_VERSION_MIGRATION[SUB_EX_OTHER].v = 1 // для начала
// // Добавили миграции для перехода на версию 2
// SUB_VERSION_MIGRATION[SUB_EX_OTHER][1] = function (prev) {
//   // Тут нужно вернуть новый формат из старого
//   return prev
// }
// Добавили миграции для перехода на версию 2
// SUB_VERSION_MIGRATION[SUB_EX_OTHER][1] = function (prev) {
//   prev?.ka.forEach(el => {
//     if (!el.resume) {
//       el.resume = {
//         method: EX_KEI_METHOD_DEFAULT(),
//         analog: '',
//         clinicalAdvantages: '',
//         econAdvantages: '',
//         results: '',
//       }
//     }
//   })
//   return prev
// }

// список для страницы MedicineOther - state - да\нет (галка), weight - баллы
export const OTHER_MEDICINE_EXP = [
  {
    id: 0,
    text: 'Преобладание заболеваемость',
    state: false,
    weight: 2,
    commentary:
      'Необходимость применения лекарственного препарата для диагностики, профилактики, лечения или реабилитации при оказании медицинской помощи в случае заболеваний и состояний, преобладающих в структуре заболеваемости и смертности граждан Российской Федерации, на основании данных государственного статистического наблюдения',
  },
  {
    id: 1,
    text: 'Воспроизведённые ЛП',
    state: false,
    weight: 1,
    commentary:
      'Наличие зарегистрированных в Российской Федерации воспроизведенных или биоаналоговых (биоподобных) лекарственных препаратов',
  },
  {
    id: 2,
    text: 'Стратегический перечень',
    state: false,
    weight: 1,
    commentary:
      'Наличие лекарственного препарата в перечне стратегически значимых лекарственных средств, производство которых должно быть обеспечено на территории Российской Федерации',
  },
  {
    id: 3,
    text: 'Субъекты',
    state: false,
    weight: 1,
    commentary:
      'Наличие лекарственного препарата в перечнях лекарственных препаратов, финансируемых за счет средств бюджетов субъектов Российской Федерации',
  },
  {
    id: 4,
    text: 'Локализация 1',
    state: false,
    group: 1,
    weight: 0,
    commentary: 'Нет',
  },
  {
    id: 5,
    text: 'Локализация 2',
    state: false,
    group: 1,
    weight: 1,
    commentary:
      'Все стадии производства, включая производство готовой лекарственной формы (без синтеза молекулы действующего вещества при производстве фармацевтических субстанций)',
  },
  {
    id: 6,
    text: 'Локализация 3',
    state: false,
    group: 1,
    weight: 2,
    commentary:
      'Первичная и вторичная упаковка, выпускающий контроль качества при наличии заключенного специального инвестиционного контракта, предполагающего создание в Российской Федерации производства готовой лекарственной формы и синтеза молекулы действующего вещества при производстве фармацевтических субстанций',
  },
  {
    id: 7,
    text: 'Локализация 4',
    state: false,
    group: 1,
    weight: 3,
    commentary:
      'Все стадии производства, включая производство готовой лекарственной формы (без синтеза молекулы действующего вещества при производстве фармацевтических субстанций), при наличии заключенного специального инвестиционного контракта, предполагающего создание в Российской Федерации производства лекарственного препарата, включая синтез молекулы действующего вещества при производстве фармацевтических субстанций',
  },
  {
    id: 8,
    text: 'Локализация 5',
    state: false,
    group: 1,
    weight: 7,
    commentary:
      'Все стадии производства, включая производство готовой лекарственной формы и синтез молекулы действующего вещества при производстве фармацевтических субстанций',
  },
  {
    id: 9,
    text: 'Локализация 6',
    state: false,
    group: 1,
    weight: 5,
    commentary:
      'все стадии производства, включая производство готовой лекарственной формы (без производства фармацевтической субстанции), при наличии заключенного специального инвестиционного контракта, предполагающего создание в Российской Федерации производства готовой лекарственной формы, включая производство фармацевтической субстанции',
  },
  {
    id: 10,
    text: 'Локализация 7',
    state: false,
    group: 1,
    weight: 7,
    commentary:
      'все стадии производства, включая производство готовой лекарственной формы и производство фармацевтической субстанции',
  },
]

// комментарий к сумме баллов для страницы MedicineOther
export const SUM_DESCRIPTION =
  'Итоговая оценка прочих данных по предложению (не менее +2 для рекомендации к включению для орфанного лекарственного препарата; не менее +4 для рекомендации к включению остальных лекарственных препаратов с учетом баллов, выставленных главным внештатным специалистом Министерства здравоохранения Российской Федерации в пункте 10 научно обоснованных рекомендаций, приведенных в приложении N 9 к Правилам формирования перечней лекарственных препаратов для медицинского применения и минимального ассортимента лекарственных препаратов, необходимых для оказания медицинской помощи, утвержденным постановлением Правительства Российской Федерации от 28 августа 2014 г. N 871).'

// определить группу в иcходном объекте по id
export function getGroup(id, obj) {
  return obj[id]?.group || 0
}

// Сброс всех галочек для тех, у кого группа=1
export function resetStates(state, index, data, options) {
  const group = getGroup(index, options)
  if (state && group > 0) {
    data.other.forEach(elem => {
      if (index !== elem.id && getGroup(elem.id, options) === group)
        elem.state = false
    })
  }
}
