var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Перечни")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"tag":"gnvlp"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-checkbox',{attrs:{"label":"Перечень ЖНВЛП","input-value":value},on:{"change":change}})]}}])}),_c('CompareRow',{attrs:{"tag":"vzn14"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-checkbox',{attrs:{"label":"Перечень 14ВЗН","input-value":value},on:{"change":change}})]}}])}),_c('CompareRow',{attrs:{"tag":"isOrphan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-checkbox',{attrs:{"label":"Орфанный статус на ГРЛС","input-value":value},on:{"change":change}})]}}])}),_c('CompareRow',{attrs:{"tag":"isOrphanByIndication"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-checkbox',{attrs:{"label":"Орфанный статус по показанию","input-value":value},on:{"change":change}})]}}])})],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Информация о препарате")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{staticClass:"d-flex",attrs:{"tag":"nameType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-select',{staticClass:"my-4",attrs:{"label":"Тип наименования","value":value,"items":_vm.nameTypes,"hide-details":""},on:{"change":change}})]}}])}),_c('CompareRow',{staticClass:"d-flex",attrs:{"tag":"mnn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-text-field',{attrs:{"value":value,"label":_vm._f("capitalize")(_vm.labelMNN)},on:{"change":change,"input":change}})]}}])}),_c('CompareRow',{staticClass:"d-flex",attrs:{"tag":"ath"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('AtcTextEditor',{attrs:{"title":"Код анатомо-терапевтическо-химической классификации лекарственного препарата","value":value,"maxlength":_vm.maxLengthAth},on:{"change":change,"input":change}})]}}])})],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Лекарственная Форма")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{staticClass:"d-flex",attrs:{"tag":"lForma"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('AutoGrowTextEditor',{attrs:{"value":value},on:{"change":change,"input":change}})]}}])})],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Государственная регистрация лекарственного препарата в Российской Федерации")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"tag":"ruNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-text-field',{attrs:{"label":"Номер РУ","value":value,"hide-details":""},on:{"change":change}})]}}])}),_c('CompareRow',{attrs:{"tag":"ruDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-text-field',{attrs:{"label":"Дата РУ","value":value,"hide-details":""},on:{"change":change}})]}}])}),_c('CompareRow',{attrs:{"tag":"ruDateConfirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-text-field',{attrs:{"label":"Дата подтверждения РУ","value":value,"hide-details":""},on:{"change":change}})]}}])}),_c('CompareRow',{attrs:{"root":"ruFiles","tag":"text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('AutoGrowTextEditor',{attrs:{"label":"Регистрационное удостоверение","value":value},on:{"change":change,"input":change}})]}}])}),_c('PlpFilesList',{attrs:{"plpKey":"ruFiles"}})],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Сведения о воспроизведённых лекарственных препаратах")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"tag":"reproducedLP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-switch',{attrs:{"input-value":value,"label":"Воспроизведенные ЛП","color":"success"},on:{"change":change}})]}}])}),_c('CompareRow',{attrs:{"tag":"reproducedLPtext"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
var data = ref.data;
return [_c('AutoGrowTextEditor',{attrs:{"label":"Комментарий","disabled":!data || !data.reproducedLP,"value":_vm.reproducedLPTextChange(value, data, change)},on:{"change":change,"input":change}})]}}])})],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Показание препарата")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"tag":"condition"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('AutoGrowTextEditor',{attrs:{"value":value,"rows":"10"},on:{"change":change,"input":change}})]}}])})],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Комментарий")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"tag":"comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('AutoGrowTextEditor',{attrs:{"value":value,"rows":"3"},on:{"change":change,"input":change}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }